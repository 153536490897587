import { useCurrentUser, useParty } from '@local/cornucopia-subscriptions'
import { ReviewGroupOrderTable } from '@local/review-order-table'
import * as React from 'react'

import { Divider } from '../../components/Divider'
import { PageHeader } from '../../components/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import {
  getPartyMembers,
  getServiceChargesFromCart
} from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const IncAuthGroupStartTabPage = () => {
  const { currentUser } = useCurrentUser()
  const { partyRefresh } = useParty()

  if (!partyRefresh || !currentUser) {
    return <LoadingPage dataTestId='inc-auth-group-start-tab-loading' />
  }

  const partyMembers = getPartyMembers(partyRefresh)

  const headerText =
    partyMembers && partyMembers.length > 1 ? 'Start group tab' : 'Start a tab'

  const serviceCharges = getServiceChargesFromCart(partyRefresh.carts[0])

  const contactInfo = getContactInfoInitialValues()

  return (
    <div data-testid='inc-auth-group-start-tab-page'>
      <PageToolbar
        left={<BackToSafeLocationButton />}
        contactInfo={contactInfo}
      />
      <div className='mt-28'>
        <PageHeader
          header={headerText}
          subheader="It's like a real tab. A temporary hold will be placed on your card until the tab is closed."
        />
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ReviewGroupOrderTable
            currentUser={currentUser}
            users={partyRefresh.party?.members || []}
            carts={partyRefresh.carts}
            serviceCharges={serviceCharges}
          />
        </div>
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <div className='z-[999999] w-full h-[400px]' id='spi-frame-anchor' />
        </div>
      </div>
    </div>
  )
}
