import { ReviewOrderTable } from '@local/review-order-table'
import * as React from 'react'
import { Divider } from '../../components/Divider/Divider'
import { PageHeader } from '../../components/PageHeader/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import { useGetPartyMemberCart } from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const IncAuthStartTabPage = () => {
  const { loading, cart } = useGetPartyMemberCart()

  if (loading) {
    return <LoadingPage dataTestId='inc-auth-start-preauth-tab-loading' />
  }

  const contactInfo = getContactInfoInitialValues()

  return (
    <div data-testid='inc-auth-start-tab-page'>
      <PageToolbar
        left={<BackToSafeLocationButton />}
        contactInfo={contactInfo}
      />
      <div className='mt-28'>
        <PageHeader
          header='Start a tab'
          subheader="It's like a real tab. A temporary hold will be placed on your card until the tab is closed."
        />
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ReviewOrderTable cart={cart} />
        </div>
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <div className='z-[999999] w-full h-[400px]' id='spi-frame-anchor' />
        </div>
      </div>
    </div>
  )
}
