import { useParty } from '@local/cornucopia-subscriptions'
import * as React from 'react'
import { OptConfig } from '../../app/types'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider'
import { PageWrapper } from '../../components/PageWrapper'
import { useConditionalRedirect } from '../../hooks/core/redirects/useConditionalRedirect'
import { useFlag } from '../../hooks/core/useFlag/useFlag'
import { LDFlags } from '../../launchdarkly/flags'
import { getCartsWithItems } from '../../utils/cart-utils/helpers'
import { isEmpty } from '../../utils/order-utils/isEmpty'
import { YourOrderPage } from '../YourOrderPage'
import { GroupStartTabPage } from './GroupStartTabPage'
import { IncAuthGroupStartTabPage } from './IncAuthGroupStartTabPage'
import { IncAuthStartTabPage } from './IncAuthStartTabPage'
import { NoAuthStartTabPage } from './NoAuthStartTabPage'
import { StartTabPage } from './StartTabPage'

const getPage = (
  optConfig: OptConfig,
  setSkipRedirect: (value: boolean) => void,
  incrementalAuthEnabled: boolean
) => {
  if (
    optConfig?.orderingConfig?.allowJoinableParty &&
    optConfig?.paymentConfig?.allowPreauth
  ) {
    return incrementalAuthEnabled ? (
      <IncAuthGroupStartTabPage />
    ) : (
      <GroupStartTabPage setSkipRedirect={setSkipRedirect} />
    )
  }

  if (
    optConfig?.orderingConfig?.allowJoinableParty &&
    !optConfig?.paymentConfig?.allowPreauth
  ) {
    return <NoAuthStartTabPage setSkipRedirect={setSkipRedirect} />
  }

  if (!optConfig?.orderingConfig?.allowTabs) {
    return <YourOrderPage />
  }

  if (!optConfig?.paymentConfig?.allowPreauth) {
    return <NoAuthStartTabPage setSkipRedirect={setSkipRedirect} />
  }

  return incrementalAuthEnabled ? (
    <IncAuthStartTabPage />
  ) : (
    <StartTabPage setSkipRedirect={setSkipRedirect} />
  )
}

export const StartTabPageContainer = () => {
  const { optConfig } = useDDIGlobals()
  const { partyRefresh } = useParty()
  const [skipRedirect, setSkipRedirect] = React.useState(false)
  const incrementalAuthEnabled = useFlag(LDFlags.INCREMENTAL_AUTH)
  const cartsWithItems = getCartsWithItems(partyRefresh)
  const allCartsEmpty = cartsWithItems && cartsWithItems.length === 0

  const order = partyRefresh && partyRefresh.order

  useConditionalRedirect({
    condition:
      !skipRedirect &&
      (Boolean(allCartsEmpty) || Boolean(order && !isEmpty(order)))
  })

  const childPage = getPage(optConfig, setSkipRedirect, incrementalAuthEnabled)

  return (
    // animated routes require stable reference to animate, keep the wrapper on top level
    <PageWrapper>{childPage}</PageWrapper>
  )
}
