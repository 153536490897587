import { useAuthContext } from '@local/cornucopia-subscriptions'
import { InputField } from '@local/tab-input'
import { useSessionStorage } from '@toasttab/do-secundo-use-session-storage'
import cx from 'classnames'
import { Form, useFormikContext } from 'formik'
import * as React from 'react'
import { useIsIntlRestaurant } from '../../../hooks/core/useIsIntlRestaurant/useIsIntlRestaurant'
import { removeUSCountryCodeFromPhone } from '../../../utils/auth-utils/auth-utils'
import { ContactInfoValues } from '../Contact.helpers'

const PhoneNumberMessage = () => (
  <p className='mt-4 type-caption text-secondary'>
    Your phone number is used for order updates. Messaging and data fees may
    apply. Message frequency varies. Reply STOP to opt out.
  </p>
)

export interface ContactFormProps {
  submitButton: React.ReactNode
  variant?: CONTACT_VARIANT
  disabled?: boolean
}

export enum CONTACT_VARIANT {
  OPTIONALITY,
  QUICK_TABS
}

export const ContactForm = ({
  submitButton,
  variant = CONTACT_VARIANT.OPTIONALITY,
  disabled = false
}: ContactFormProps) => {
  const hasQuickTabStyles = variant === CONTACT_VARIANT.QUICK_TABS
  const { setFieldValue, initialValues, values } =
    useFormikContext<ContactInfoValues>()
  const { authContext } = useAuthContext()
  const [_, setStoredUserInfo] = useSessionStorage(
    'tabs_spa_contact_form_info',
    values
  )
  const [loginTracked, setLoginTracked] = useSessionStorage(
    'tabs_spa_contact_form_login',
    false
  )

  const phoneMask = useIsIntlRestaurant()
    ? [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    : [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]

  // when logging in override contact form with name and phone saved to account but only upon first logging in
  React.useEffect(() => {
    if (!authContext?.user || !authContext?.authenticated) {
      return
    }

    const formattedName = `${authContext.user.firstName} ${authContext.user.lastName}`
    const formattedPhone =
      removeUSCountryCodeFromPhone(authContext.user.phone) || ''

    if (!loginTracked) {
      if (values.fullName !== formattedName) {
        setFieldValue('fullName', formattedName)
      }
      if (values.phone !== formattedPhone) {
        setFieldValue('phone', formattedPhone)
      }
      setLoginTracked(true)
    }
  }, [
    authContext?.authenticated,
    authContext?.user,
    loginTracked,
    setFieldValue,
    setLoginTracked,
    values
  ])

  // retain changes to input value even if navigate away then back - only updates on unmount
  React.useEffect(() => {
    return () => {
      if (values.fullName || values.phone) {
        setStoredUserInfo(values)
      }
    }
  }, [setStoredUserInfo, values, initialValues])

  return (
    <Form className={`mx-6 ${disabled ? 'mt-6' : 'mt-8'}`}>
      <div className={cx({ 'mb-24': !hasQuickTabStyles })}>
        <InputField
          name='fullName'
          inputProps={{
            id: 'fullName',
            label: 'Your full name *',
            type: 'string',
            className: 'flex-1',
            disabled
          }}
        />
        <InputField
          name='phone'
          inputProps={{
            id: 'phone',
            disabled,
            label: 'Your phone number *',
            type: 'tel',
            mask: phoneMask
          }}
        />
        {!hasQuickTabStyles && <PhoneNumberMessage />}
      </div>
      {submitButton}
      {hasQuickTabStyles && <PhoneNumberMessage />}
    </Form>
  )
}
