import { useParty } from '@local/cornucopia-subscriptions'
import { ReviewOrderTable } from '@local/review-order-table'
import { order as orderFn, updatePartyMember } from 'cornucopia-apis'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ORDERING_ROUTES } from '../../app/OrderingRoutes'
import { Divider } from '../../components/Divider/Divider'
import { PageHeader } from '../../components/PageHeader/PageHeader'
import { PageToolbar } from '../../components/PageToolbar'
import { BackToSafeLocationButton } from '../../components/PageToolbar/BackToSafeLocationButton/BackToSafeLocationButton'
import { ContactFormWrapper } from '../../components/StartTabForms'
import { ContactFormValues } from '../../components/StartTabForms/StartTabForms.types'
import { getContactInfoInitialValues } from '../../utils/auth-utils/auth-utils'
import { useGetPartyMemberCart } from '../../utils/cart-utils/helpers'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export const NoAuthStartTabPage = ({
  setSkipRedirect
}: {
  setSkipRedirect: (value: boolean) => void
}) => {
  const { partyRefresh } = useParty()
  const navigate = useNavigate()
  const { cart } = useGetPartyMemberCart()

  const onSubmit = async (values: ContactFormValues) => {
    setSkipRedirect(true)
    await new Promise((resolve) => {
      updatePartyMember({
        name: values.firstName + ' ' + values.lastName,
        phoneNumber: values.phone as string
      }).subscribe((result: any) => {
        if (result.kind === 'OK') {
          orderFn({
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone as string,
            email: values?.email || 'dummy@email.com'
          }).subscribe((response) => {
            if (response.kind === 'OK') {
              navigate(ORDERING_ROUTES.CONFIRM, { replace: true })
            } else {
              setSkipRedirect(false)
            }
            resolve(result)
          })
        } else {
          setSkipRedirect(false)
          resolve(result)
        }
      })
    })
  }

  if (!partyRefresh || !cart) {
    return <LoadingPage dataTestId='noauth-start-tab-loading' />
  }

  const contactInfo = getContactInfoInitialValues()

  return (
    <div data-testid='start-tab-page'>
      <PageToolbar
        left={<BackToSafeLocationButton />}
        contactInfo={contactInfo}
      />
      <div className='mt-28'>
        <PageHeader
          header='Start a tab'
          subheader="It's like a real tab. Continue ordering and pay once at meal's end."
        />
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ReviewOrderTable cart={cart} />
        </div>
        <div className='my-8'>
          <Divider />
        </div>
        <div>
          <ContactFormWrapper
            initialContactInfo={contactInfo}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}
